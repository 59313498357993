/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:cc6a3ae7-6273-4849-8dcd-bf02cd167166",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_xR23SAifY",
    "aws_user_pools_web_client_id": "2qcqnbviti6hbk799sfno45s8o",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://y7agz2ltung2hmiwa2wkyyphou.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
