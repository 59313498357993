/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import Amplify, { Auth, I18n } from 'aws-amplify';
import awsConfig from './src/aws-exports';
import { setUser } from './src/utils/auth';

// required
Amplify.configure(awsConfig);

// optional lang settings
const authScreenLabels = {
  en: {
    'Sign Up': 'Create new account',
    'Sign Up Account': 'Create a new account'
  },
  es: {
    'Sign in to your account': 'Ingresar a tu cuenta',
    'Enter your username': 'Ingresa tu nombre de usuario',
    'Enter your password': 'Ingresa tu contraseña',
    'No account? ': 'No eres usuario? ',
    'Create account': 'Crear cuenta',
    'Create a new account': 'Crear una nueva cuenta',
    'Create Account': 'Crear Cuenta',
    'Have an account? ': 'Ya tienes cuenta? ',
    'Sign in': 'Iniciar sesión',
    'Confirmation Code': 'Código confirmación',
    'Lost your code? ': 'Perdió su código?',
    'Resend Code': 'Reenviar código'
  }
};

I18n.setLanguage('es');
I18n.putVocabularies(authScreenLabels);

export const onRouteUpdate = (state, page, pages) => {
  Auth.currentAuthenticatedUser()
    .then((user) => {
      const userInfo = {
        ...user.attributes,
        username: user.username
      };
      setUser(userInfo);
    })
    .catch((err) => {
      window.localStorage.setItem('localUser', null);
    });
};
